import React from 'react';

interface SitePreviewSVGProps {
  text: string;
}

const SitePreviewSVG: React.FC<SitePreviewSVGProps> = ({ text }) => {
  return (
    <svg
      viewBox="46.2898 85.6184 399.525 27.9259"
      width="399.525"
      height="27.926"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="XMLID_25_"
        opacity="0.5"
        transform="matrix(0.2853749990463257, 0, 0, 0.20840200781822205, 46.28977966308593, 49.1480712890625)"
        fill="white"
      >
        <rect
          id="XMLID_78_"
          x="0"
          y="175"
          width="1400"
          height="134"
          rx="22.001"
          ry="22.001"
        />
      </g>
      <g
        id="XMLID_26_"
        opacity="0.8"
        transform="matrix(0.2853749990463257, 0, 0, 0.20840200781822205, 46.28977966308593, 49.1480712890625)"
        fill="#e7ecf3"
      >
        <path
          id="XMLID_77_"
          fill="#e7ecf3"
          d="M1203,287H197c-14.4,0-26-11.6-26-26v-38c0-14.4,11.6-26,26-26h1006c14.4,0,26,11.6,26,26   v38C1229,275.4,1217.4,287,1203,287z"
        />
      </g>
      <g
        id="XMLID_28_"
        opacity="0.8"
        transform="matrix(0.2853749990463257, 0, 0, 0.20840200781822205, 46.28977966308593, 49.1480712890625)"
      >
        <circle id="XMLID_76_" fill="green" cx="126.5" cy="242" r="10" />
      </g>
      <g
        id="XMLID_36_"
        opacity="0.8"
        transform="matrix(0.2853749990463257, 0, 0, 0.20840200781822205, 46.28977966308593, 49.1480712890625)"
      >
        <circle id="XMLID_75_" fill="orange" cx="85.5" cy="242" r="10" />
      </g>
      <g
        id="XMLID_37_"
        opacity="0.8"
        transform="matrix(0.2853749990463257, 0, 0, 0.20840200781822205, 46.28977966308593, 49.1480712890625)"
      >
        <circle id="XMLID_1_" fill="red" cx="44.5" cy="242" r="10" />
      </g>
      <g
        id="XMLID_41_"
        transform="matrix(0.2853749990463257, 0, 0, 0.20840200781822205, 46.28977966308593, 49.1480712890625)"
      >
        <g id="XMLID_40_">
          <path
            id="XMLID_74_"
            fill="#939598"
            d="M42,245.5c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5-5c0.4-0.4,1-0.4,1.4,0    s0.4,1,0,1.4l-5,5C42.5,245.4,42.3,245.5,42,245.5z"
          />
        </g>
        <g id="XMLID_32_">
          <path
            id="XMLID_73_"
            fill="#939598"
            d="M47,245.5c-0.3,0-0.5-0.1-0.7-0.3l-5-5c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l5,5    c0.4,0.4,0.4,1,0,1.4C47.5,245.4,47.2,245.5,47,245.5z"
          />
        </g>
      </g>
      <g
        id="XMLID_42_"
        transform="matrix(0.2853749990463257, 0, 0, 0.20840200781822205, 46.28977966308593, 49.1480712890625)"
      >
        <g id="XMLID_43_">
          <path
            id="XMLID_72_"
            fill="#939598"
            d="M89,243h-7c-0.6,0-1-0.4-1-1s0.4-1,1-1h7c0.6,0,1,0.4,1,1S89.6,243,89,243z"
          />
        </g>
      </g>
      <g
        id="XMLID_45_"
        transform="matrix(0.2853749990463257, 0, 0, 0.20840200781822205, 46.28977966308593, 49.1480712890625)"
      >
        <g id="XMLID_47_">
          <path
            id="XMLID_71_"
            fill="#939598"
            d="M126.5,246.5c-0.6,0-1-0.4-1-1v-7c0-0.6,0.4-1,1-1s1,0.4,1,1v7    C127.5,246.1,127.1,246.5,126.5,246.5z"
          />
        </g>
        <g id="XMLID_46_">
          <path
            id="XMLID_70_"
            fill="#939598"
            d="M130,243h-7c-0.6,0-1-0.4-1-1s0.4-1,1-1h7c0.6,0,1,0.4,1,1S130.6,243,130,243z"
          />
        </g>
      </g>
      <g
        id="XMLID_39_"
        transform="matrix(0.2853749990463257, 0, 0, 0.20840200781822205, 46.28977966308593, 49.1480712890625)"
      >
        <g id="XMLID_30_">
          <g id="XMLID_38_">
            <path
              id="XMLID_67_"
              fill="#939598"
              d="M234.1,271c-16,0-29-13-29-29s13-29,29-29s29,13,29,29S250.1,271,234.1,271z M234.1,218     c-13.2,0-24,10.8-24,24s10.8,24,24,24c13.2,0,24-10.8,24-24S247.3,218,234.1,218z"
            />
          </g>
          <g id="XMLID_31_">
            <path
              id="XMLID_64_"
              fill="#939598"
              d="M234.1,231.6c-7.2,0-14-2.6-19.4-7.4c-0.5-0.5-0.8-1.2-0.8-1.9s0.3-1.4,0.8-1.9     c5.3-4.8,12.2-7.4,19.4-7.4c7.2,0,14,2.6,19.4,7.4c0.5,0.5,0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9     C248.1,228.9,241.3,231.6,234.1,231.6z M220.4,222.3c4,2.8,8.8,4.3,13.7,4.3c4.9,0,9.7-1.5,13.7-4.3c-4-2.8-8.8-4.3-13.7-4.3     C229.2,218,224.4,219.5,220.4,222.3z"
            />
          </g>
          <g id="XMLID_29_">
            <path
              id="XMLID_61_"
              fill="#939598"
              d="M234.1,271c-7.2,0-14-2.6-19.4-7.4c-0.5-0.5-0.8-1.2-0.8-1.9s0.3-1.4,0.8-1.9     c5.3-4.8,12.2-7.4,19.4-7.4c7.2,0,14,2.6,19.4,7.4c0.5,0.5,0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9C248.1,268.4,241.3,271,234.1,271z      M220.4,261.7c4,2.8,8.8,4.3,13.7,4.3c4.9,0,9.7-1.5,13.7-4.3c-4-2.8-8.8-4.3-13.7-4.3C229.2,257.4,224.4,258.9,220.4,261.7z"
            />
          </g>
          <g id="XMLID_35_">
            <path
              id="XMLID_16_"
              fill="#939598"
              d="M234.1,271c-1.4,0-2.5-1.1-2.5-2.5v-53c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v53     C236.6,269.9,235.5,271,234.1,271z"
            />
          </g>
          <g id="XMLID_34_">
            <path
              id="XMLID_15_"
              fill="#939598"
              d="M234.1,271c-0.9,0-1.7-0.5-2.2-1.3c-0.7-1.2-0.2-2.7,1-3.4c0.6-0.3,13.7-8,13.7-24.3     c0-16.4-13.6-24.2-13.7-24.3c-1.2-0.7-1.6-2.2-1-3.4c0.7-1.2,2.2-1.6,3.4-1c0.7,0.4,16.3,9.4,16.3,28.7s-15.6,28.3-16.3,28.7     C234.9,270.9,234.5,271,234.1,271z"
            />
          </g>
          <g id="XMLID_33_">
            <path
              id="XMLID_14_"
              fill="#939598"
              d="M234.1,271c-0.4,0-0.8-0.1-1.2-0.3c-0.7-0.4-16.3-9.4-16.3-28.7s15.6-28.3,16.3-28.7     c1.2-0.7,2.7-0.2,3.4,1c0.7,1.2,0.2,2.7-1,3.4c-0.6,0.3-13.7,8-13.7,24.3c0,16.4,13.6,24.2,13.7,24.3c1.2,0.7,1.6,2.2,1,3.4     C235.8,270.5,235,271,234.1,271z"
            />
          </g>
          <g id="XMLID_27_">
            <path
              id="XMLID_13_"
              fill="#939598"
              d="M259.8,244.5h-51.4c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h51.4c1.4,0,2.5,1.1,2.5,2.5     S261.2,244.5,259.8,244.5z"
            />
          </g>
        </g>
        <g id="XMLID_49_">
          <polygon
            fill="#939598"
            points="1171.4,257.5 1192.4,242 1171.4,226.5 1171.4,234.2 1151.7,234.2 1151.7,249.8 1171.4,249.8   "
          />
        </g>
      </g>
      <g
        id="XMLID_51_"
        opacity="0.8"
        transform="matrix(0.2853749990463257, 0, 0, 0.20840200781822205, 46.28977966308593, 49.1480712890625)"
      >
        <g id="XMLID_50_">
          <path
            id="XMLID_12_"
            fill="#939598"
            d="M1273.5,247.1c-0.5,0-0.9-0.2-1.2-0.6l-5.4-6.9c-0.5-0.7-0.4-1.6,0.3-2.1s1.6-0.4,2.1,0.3    l4.3,5.4l4.3-5.4c0.5-0.7,1.5-0.8,2.1-0.3s0.8,1.5,0.3,2.1l-5.4,6.9C1274.4,246.9,1274,247.1,1273.5,247.1z"
          />
        </g>
        <g id="XMLID_48_">
          <path
            id="XMLID_10_"
            fill="#939598"
            d="M1273.5,247.1c-0.8,0-1.5-0.7-1.5-1.5v-14.3c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v14.3    C1275,246.4,1274.3,247.1,1273.5,247.1z"
          />
        </g>
        <g id="XMLID_44_">
          <path
            id="XMLID_9_"
            fill="#939598"
            d="M1283,254.2h-19c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h19c0.8,0,1.5,0.7,1.5,1.5    S1283.8,254.2,1283,254.2z"
          />
        </g>
      </g>
      <g
        id="XMLID_53_"
        opacity="0.8"
        transform="matrix(0.2853749990463257, 0, 0, 0.20840200781822205, 46.28977966308593, 49.1480712890625)"
      >
        <g id="XMLID_56_">
          <path
            id="XMLID_8_"
            fill="#939598"
            d="M1319.9,239.7c-0.4,0-0.9-0.2-1.2-0.6l-4.3-5.4l-4.3,5.4c-0.5,0.7-1.5,0.8-2.1,0.3    c-0.7-0.5-0.8-1.5-0.3-2.1l5.4-6.9c0.6-0.7,1.8-0.7,2.4,0l5.4,6.9c0.5,0.7,0.4,1.6-0.3,2.1    C1320.6,239.6,1320.3,239.7,1319.9,239.7z"
          />
        </g>
        <g id="XMLID_55_">
          <path
            id="XMLID_7_"
            fill="#939598"
            d="M1314.5,247.1c-0.8,0-1.5-0.7-1.5-1.5v-14.3c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v14.3    C1316,246.4,1315.3,247.1,1314.5,247.1z"
          />
        </g>
        <g id="XMLID_58_">
          <path
            id="XMLID_6_"
            fill="#939598"
            d="M1324,254.2h-19c-0.8,0-1.5-0.7-1.5-1.5v-2.3c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v0.8h16    v-0.8c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v2.3C1325.5,253.6,1324.8,254.2,1324,254.2z"
          />
        </g>
      </g>
      <g
        id="XMLID_52_"
        opacity="0.8"
        transform="matrix(0.2853749990463257, 0, 0, 0.20840200781822205, 46.28977966308593, 49.1480712890625)"
      >
        <g id="XMLID_54_">
          <path
            id="XMLID_5_"
            fill="#939598"
            d="M1347.4,246.2c-0.8,0-1.5-0.7-1.5-1.5v-10.9c0-0.8,0.7-1.5,1.5-1.5h10.9    c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5h-9.4v9.4C1348.9,245.5,1348.2,246.2,1347.4,246.2z"
          />
        </g>
        <g id="XMLID_57_">
          <path
            id="XMLID_2_"
            fill="#939598"
            d="M1363.6,251.6h-10.9c-0.8,0-1.5-0.7-1.5-1.5v-10.9c0-0.8,0.7-1.5,1.5-1.5h10.9    c0.8,0,1.5,0.7,1.5,1.5v10.9C1365.1,251,1364.5,251.6,1363.6,251.6z M1354.3,248.6h7.9v-7.9h-7.9V248.6z"
          />
        </g>
      </g>
      <text
        style={{
          whiteSpace: 'pre',
          fill: 'rgb(51, 51, 51)',
          fontFamily: 'Arial, sans-serif',
          fontSize: '15.7px',
        }}
        x="126.877"
        y="103.687"
        transform="matrix(0.9399520754814148, 0, 0, 0.7090069651603699, 7.595960140228279, 29.463363647460934)"
      >
        {text}
      </text>
    </svg>
  );
};

export default SitePreviewSVG;
