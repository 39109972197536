import React from 'react';

interface ByWCGAGuildelinesSVGProps {
  text: string;
}

const ByWCGAGuildelinesSVG: React.FC<ByWCGAGuildelinesSVGProps> = ({ text }) => {
  return (
    <svg
                        width="40"
                        height="20"
                        viewBox="0 0 40 20"
                        fill="none"
                      >
                        <path
                          d="M9.47064 0.106445L12.8615 11.6349L16.2523 0.106445H18.7075H25.4456V1.25223L21.9841 7.21546C23.2 7.60583 24.1197 8.31532 24.7432 9.34393C25.3667 10.3731 25.6788 11.5807 25.6788 12.9679C25.6788 14.6836 25.2225 16.1256 24.3104 17.2943C23.3984 18.4631 22.2173 19.0477 20.7677 19.0477C19.6761 19.0477 18.7252 18.7009 17.915 18.0073C17.1043 17.3138 16.5043 16.3746 16.1145 15.1894L18.0316 14.3945C18.3125 15.1117 18.6828 15.6769 19.1427 16.0897C19.6025 16.503 20.1442 16.7091 20.7677 16.7091C21.4224 16.7091 21.9759 16.3428 22.4281 15.6104C22.8803 14.8774 23.1064 13.9971 23.1064 12.9673C23.1064 11.8292 22.8644 10.9484 22.3816 10.3248C21.8199 9.5924 20.939 9.22558 19.7385 9.22558H18.8035V8.10335L22.0772 2.44393H18.1252L17.9003 2.82664L13.094 19.0466H12.8603L9.3523 7.3079L5.84489 19.0466H5.61114L0 0.106445H2.45524L5.84607 11.6349L8.13763 3.87174L7.0154 0.106445H9.47064Z"
                          fill={
                            text === 'By WCGA Guidelines'
                              ? 'white'
                              : 'black'
                          }
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M38.2045 0.106445C37.7223 0.106445 37.2896 0.280138 36.9616 0.613391C36.6136 0.966664 36.4199 1.42356 36.4199 1.89577C36.4199 2.36798 36.6042 2.80545 36.9422 3.14871C37.2854 3.49668 37.7276 3.68568 38.2051 3.68568C38.672 3.68568 39.1248 3.49668 39.4775 3.15401C39.8155 2.82605 39.9997 2.38858 39.9997 1.89636C39.9997 1.41885 39.8107 0.971963 39.4828 0.638709C39.1389 0.290147 38.6915 0.106445 38.2045 0.106445ZM39.7607 1.91108C39.7607 2.32382 39.6011 2.70653 39.3085 2.98974C39 3.28825 38.6173 3.44722 38.1945 3.44722C37.7971 3.44722 37.4044 3.28354 37.1112 2.98503C36.8179 2.68651 36.6537 2.3038 36.6537 1.89636C36.6537 1.48892 36.8227 1.0909 37.1259 0.782962C37.4091 0.494456 37.7918 0.340783 38.2092 0.340783C38.6367 0.340783 39.0194 0.500344 39.3179 0.802981C39.6064 1.0909 39.7607 1.47832 39.7607 1.91108ZM38.254 0.84714H37.4886V2.87551H37.8713V2.01058H38.2493L38.662 2.87551H39.0895L38.6367 1.95112C38.9299 1.89106 39.0989 1.69264 39.0989 1.40413C39.0989 1.03614 38.821 0.84714 38.254 0.84714ZM38.1845 1.09561C38.5425 1.09561 38.7062 1.19511 38.7062 1.44358C38.7062 1.68204 38.5425 1.76683 38.1939 1.76683H37.8707V1.09561H38.1845Z"
                          fill={
                            text === 'By WCGA Guidelines'
                              ? 'white'
                              : 'black'
                          }
                        ></path>
                        <path
                          d="M35.1923 0L35.5903 2.41815L34.1825 5.11185C34.1825 5.11185 33.642 3.96901 32.7441 3.33666C31.9875 2.8038 31.4947 2.68781 30.724 2.84679C29.7343 3.0511 28.612 4.23456 28.1222 5.69357C27.5363 7.43933 27.5304 8.28424 27.5098 9.06026C27.4768 10.3044 27.6729 11.0398 27.6729 11.0398C27.6729 11.0398 26.818 9.45828 26.8262 7.14199C26.8315 5.48867 27.0918 3.98962 27.8566 2.51C28.5296 1.20937 29.5299 0.428637 30.4178 0.336787C31.3358 0.241992 32.0611 0.684171 32.6217 1.16285C33.2105 1.66568 33.8051 2.76494 33.8051 2.76494L35.1923 0Z"
                          fill={
                            text === 'By WCGA Guidelines'
                              ? 'white'
                              : 'black'
                          }
                        ></path>
                        <path
                          d="M35.3654 13.682C35.3654 13.682 34.7431 14.7942 34.3557 15.2229C33.9677 15.6515 33.2741 16.4063 32.4174 16.7837C31.5607 17.1611 31.1115 17.2324 30.2648 17.1511C29.4187 17.0699 28.6327 16.58 28.3571 16.3757C28.0816 16.1714 27.3774 15.5697 26.9794 15.0085C26.5813 14.4474 25.959 13.3252 25.959 13.3252C25.959 13.3252 26.3058 14.4498 26.523 14.9273C26.6479 15.2022 27.0318 16.0424 27.5764 16.7737C28.0845 17.4561 29.0713 18.6308 30.571 18.8957C32.0706 19.1613 33.101 18.4877 33.3559 18.3246C33.6109 18.1615 34.1484 17.7111 34.4887 17.3472C34.8438 16.9674 35.18 16.4829 35.366 16.192C35.502 15.98 35.7234 15.549 35.7234 15.549L35.3654 13.682Z"
                          fill={
                            text === 'By WCGA Guidelines'
                              ? 'white'
                              : 'black'
                          }
                        ></path>
                      </svg>
  );
};

export default ByWCGAGuildelinesSVG;
